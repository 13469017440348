import { defineItemScript } from "@local/lamina-script";
import { DimText } from "dimtext";

const dt = new DimText();

function parseItemScript(script) {
  const body = script
    .replace(/^\s*export\s+default\s+.*?;?\s*$/gm, "")
    .replace(/^\s*import\s.*?;?\s*$/gm, "")
    .replace(/^\s*export\s.*?;?\s*$/gm, "");

  try {
    return new Function(
      "defineItemScript",
      `
			"use strict";
			${body}
			return script;
		`,
    )(defineItemScript);
  } catch (e) {
    console.error(e);
    return null;
  }
}

function parseItemParams(input) {
  return Object.keys(input).map((key) => {
    const d = input[key];
    let value = d.default;
    if (d.type === "dimtext" && typeof d.default === "string") {
      value = dt.parse(d.default).value;
    }

    return {
      key,
      type: d.type,
      label: d.label || key,
      value,
    };
  });
}

export { parseItemScript, parseItemParams };
